@font-face {
  font-family: "Colfax";
  src: url("./assets/fonts/Colfax-Regular.eot");
  src: url("./assets/fonts/Colfax-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Colfax-Regular.woff") format("woff"),
    url("./assets/fonts/Colfax-Regular.ttf") format("truetype"),
    url("./assets/fonts/Colfax-Regular.svg#svgFontName") format("svg");
}

@font-face {
  font-family: "Colfax-Medium";
  src: url("./assets/fonts/Colfax-Medium.eot");
  src: url("./assets/fonts/Colfax-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Colfax-Medium.woff") format("woff"),
    url("./assets/fonts/Colfax-Medium.ttf") format("truetype"),
    url("./assets/fonts/Colfax-Medium.svg#svgFontName") format("svg");
}

@font-face {
  font-family: "Colfax-Bold";
  src: url("./assets/fonts/Colfax-Bold.eot");
  src: url("./assets/fonts/Colfax-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Colfax-Bold.woff") format("woff"),
    url("./assets/fonts/Colfax-Bold.ttf") format("truetype"),
    url("./assets/fonts/Colfax-Bold.svg#svgFontName") format("svg");
}
@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-Italic.eot");
  src: url("./assets/fonts/Merriweather-Italic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-Italic.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-Italic.woff") format("woff"),
    url("./assets/fonts/Merriweather-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-Regular.eot");
  src: url("./assets/fonts/Merriweather-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-Regular.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-Regular.woff") format("woff"),
    url("./assets/fonts/Merriweather-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-Bold.eot");
  src: url("./assets/fonts/Merriweather-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-Bold.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-Bold.woff") format("woff"),
    url("./assets/fonts/Merriweather-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-Black.eot");
  src: url("./assets/fonts/Merriweather-Black.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-Black.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-Black.woff") format("woff"),
    url("./assets/fonts/Merriweather-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-LightItalic.eot");
  src: url("./assets/fonts/Merriweather-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-LightItalic.woff") format("woff"),
    url("./assets/fonts/Merriweather-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-Light.eot");
  src: url("./assets/fonts/Merriweather-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-Light.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-Light.woff") format("woff"),
    url("./assets/fonts/Merriweather-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-BoldItalic.eot");
  src: url("./assets/fonts/Merriweather-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-BoldItalic.woff") format("woff"),
    url("./assets/fonts/Merriweather-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-BlackItalic.eot");
  src: url("./assets/fonts/Merriweather-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Merriweather-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/Merriweather-BlackItalic.woff") format("woff"),
    url("./assets/fonts/Merriweather-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
