@import "./fonts.css";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body,
html {
  font-family: "Colfax", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-width: 375px;
  max-width: 375px;
  max-height: 800px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
}
body {
  border: solid 1px;
  /* padding: 2rem 0; */
  box-sizing: border-box;
}
body > div {
  display: flex;
  flex: 1;
  flex-direction: column;
}
#root,
#root > * {
  max-height: 100%;
  min-height: 100%;
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  font-family: Merriweather, "Times New Roman", Times, serif;
  line-height: 24px;
  text-align: center;
}

h1 {
  margin-top: 1em;
  font-size: 20px;
}

input.reva-input {
  width: 270px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #d5cfcf;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 11px 20px;
  margin-top: 20px;
  font-family: Colfax;
  font-size: 16px;
  line-height: 19px;
  font-weight: bolder;
}
input.reva-input::placeholder {
  font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #b4b4b4;
  font-weight: bolder;
}
input.reva-input.full {
  width: 100%;
  padding-left: 9px;
  padding-right: 9px;
}
input.reva-input.big {
  width: 212px;
  padding-left: 9px;
  padding-right: 9px;
  margin-right: 6px;
}
input.reva-input.medium {
  width: 157px;
  padding-left: 9px;
  padding-right: 9px;
  margin-right: 6px;
}
input.reva-input.small {
  width: 83px;
  padding-left: 9px;
  padding-right: 9px;
  margin-right: 6px;
}
.form-group {
  display: flex;
}

.form-group.reva-form-group {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.form-group.reva-form-group button.reva-button {
  margin-top: auto;
}

.form-group.reva-form-group.property-form {
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0;
}

.form-group.reva-form-group.property-form input.reva-input {
  display: inline-block;
}

.has-points {
  display: flex;
  display:-webkit-flex;
  flex: 1 auto;
  background: #f7f7f7;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 0.5rem;
  max-height: 323px;
  overflow: auto;
}

.has-points-inner-wrapper {
  margin:0 auto;
}

.has-map {
  display: flex;
  display:-webkit-flex;
  flex: 1 auto;
  background: green;
  z-index: -1;
  
}

.reva-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff12cb;
  display: inline-flex;
  width: auto;
  padding: 0rem 3rem;
  height: 60px;
  border: none;
  text-decoration: none;
  border-radius: 3px;
  font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
  color: white;
}
.reva-button.inverted {
  background-color: white !important;
  color: #ff12cb !important;
  border: 1px solid #ff12cb;
}

.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 375px;
  display: flex;
  justify-content: space-evenly;
  bottom: 1.25rem;
}
.sticky-bottom-preview {
  position: fixed;
  width: 100%;
  max-width: 375px;
  display: flex;
  justify-content: space-evenly;
  bottom: 0;
  width: 100%;
  max-width: 375px;
  height: 192px;
  flex-direction: column;
  background-color: #fff;
}

.goBack {
  display: block;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  font-family: Colfax;
  font-size: 12px;
  text-decoration: none;
  color: black;
  z-index: 9;
  background-color: white;
  padding: 0.25rem 1rem;
}
.title_highlight {
  font-weight: bolder;
  text-decoration: underline;
  margin: 0 0.25em;
  display: inline-block;
}

.sticky-bottom-preview .reva-button {
  margin: auto;
  margin-bottom: 0;
}

.sticky-bottom-preview .cancel-tour {
  font-family: "Colfax", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: auto;
  border: none;
  background: none;
  margin-top: 1em;
  cursor: pointer;
}

.add_property_link {
  text-decoration: none;
}
.add_property {
  width: 375px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;

}

.plus_text {
  font-family: "Colfax-Medium", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #B4B4B4;
  margin-left: 49px;
}